import moment from 'moment-timezone'
import report from '@/services/report'

export default {
    excel(shopObjectId, startDate, endDate, filename = 'file') {
        const uid = `${localStorage.getItem('shopsUid')}`

        const startAt = moment(String(startDate)).format('YYYY-MM-DD')
        const endAt = moment(String(endDate)).format('YYYY-MM-DD')

        const params = {
            'shopObjectId': shopObjectId,
            'startAt': startAt,
            'endAt': endAt,
        }
        const headers = {
            'shopObjectId': shopObjectId
        }

        report({
            url: '/receipt/v1.0/productsellbyplureport/' + uid + '/excel',
            params: params,
            method: 'GET',
            headers: headers,
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
                'download',
                filename + '.xlsx'
            ) //or any other extension
            document.body.appendChild(link)
            link.click()
        }).catch((error) => {
            console.log(error)
        })
    }
}