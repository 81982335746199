<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="getPluReportReport"
    ></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <div class="col-md-10 col-8 col-sm-10 d-inline-flex align-items-center">
            <h2 id="PLUReportTitle" class="mb-0 font-weight-normal">
              {{ $t('report.textPlu') }}
            </h2>
            <HelpButton :isHeader="false" class="ml-2"></HelpButton>
            </div>
            <div class="col-md-2 col-sm-3 text-right text-success">
              <CButton
                id="PLUReportExportButton"
                v-on:click="exportPluReport()"
                v-if="isExport"
                block
                color="info"
              >
              <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
            </CButton>
            </div>
          </div>
          <CRow>
            <CCol sm="12" lg="12">
              <show-date></show-date>
              <hr />
              <DataTable
                id="PLUReportDatatable"
                style="white-space: nowrap;"
                :items="items"
                :fields="fields"
                :loading="loading"
                hover
                border
              >
              <template #onhandQty="{ item }">
                  <td v-if="floatValue(item.onhandQty) < 0.00" class="text-right text-danger">{{ item.onhandQty }}</td>
                  <td v-else class="text-right text-dark">{{ item.onhandQty }}</td>
              </template>
              </DataTable>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getPluReportReport">
          </pagination>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import Pagination from '@/containers/Pagination'
import util from '@/util/util'
import DataTable from '@/containers/DataTable'
import permis from '@/util/permission'
import fileExport from '@/util/fileExport'
import HelpButton from '../../containers/HelpButton.vue'

export default {
  components: {
    Pagination,
    DataTable,
    HelpButton
  },
  data() {
    return {
      data: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      loadingButton: true,
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users', 'permissions']),
    isPermission() {
      return permis.findPermissionRead('report', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('report', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      return shop.uid
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        {
          key: 'id',
          label: this.$i18n.t('#'),
          _classes: 'text-center text-dark font-weight-normal',
          _style: 'width:5%',
        },
        { key: 'productPLU', label: this.$i18n.t('pluCode'),_classes: 'text-dark font-weight-normal', },
        {
          key: 'name',
          label: this.$i18n.t('productName'),
          _style: 'width:30%',
          _classes: 'text-dark font-weight-normal',
        },
        { key: 'category', label: this.$i18n.t('category'), _classes: 'text-dark font-weight-normal', },
        {
          key: 'quantity',
          label: this.$i18n.t('countSales'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'netAmount',
          label: this.$i18n.t('sales'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'onhandQty',
          label: this.$i18n.t('onhandQty'),
          _classes: 'text-right text-dark font-weight-normal',
        },
      ]
    },
    items() {
      let data = this.data
      let detail = []
      let start = this.meta_data.current_page * 50 - 50
      let catagory = ''
      let onhandQty = ''

      for (let i = 0; i < data.length; i++) {
        if (data[i].category == undefined) {
          catagory = '-'
        } else {
          catagory = data[i].category.name
        }
        let plu = '-'
        let name = '-'
        if (data[i].productPLU === undefined) {
          plu = '-'
        } else {
          if (data[i].productPLU.PLUCode === undefined) {
            plu = '-'
          } else {
            plu = data[i].productPLU.PLUCode
          }
          if (data[i].productPLU.name === undefined) {
            name = '-'
          } else {
            name = data[i].productPLU.name
          }
        }

       
        if (data[i].ProductSKU) {
          if (
            data[i].ProductSKU.skuType === 'BOM' ||
            data[i].ProductSKU.skuType === 'SV'
          ) {
            onhandQty = this.$t('nonStock')
          } else {
            onhandQty = data[i].onhandQty || 0
          }
        } else {
          onhandQty = data[i].onhandQty || 0
        }
        detail.push({
          id: start + i + 1,
          productPLU: plu,
          name: name,
          category: catagory,
          quantity: data[i].quantity,
          onhandQty: onhandQty,
          netAmount: util.convertCurrency(data[i].netAmount),
        })
      }
      return detail
    },
  },
  created() {
    this.getPluReportReport()
  },
  methods: {
    ...util,
    getPluReportReport(page = 1) {
      this.loadingButton = false
      this.loading = true
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        page: page,
        limit: 50,
      }
      const headers = { shopObjectId: shopObjectId }
      const url = '/receipt/v1.0/productsellbyplureport/' + uid + '/data'
      axios({
        url: url,
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          if (res.data.error.code == 0) {
            if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
            } else {
              this.meta_data.items = res.data.paginate.items
            }
            this.data = res.data.data
            this.meta_data.last_page = res.data.paginate.pageCount
            this.meta_data.current_page = res.data.paginate.currentPage
            this.meta_data.itemCount = res.data.paginate.itemCount
            this.meta_data.limit = res.data.paginate.perPage
            this.loading = false
            this.loadingButton = true
          } else {
            // handle error
            this.data = []
            this.loading = false
            this.loadingButton = true
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportPluReport() {
      
      // const uid = `${localStorage.getItem('shopsUid')}`
      // const shopObjectId = this.shopObjectId

      // const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      // const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      let filename = 'รายงานสรุปจำนวนการขายสินค้าตามขนาดบรรจุ'
      fileExport.excel(this.shopObjectId, this.date.start, this.date.end, filename)
      
      // const params = {
      //   shopObjectId: shopObjectId,
      //   startAt: startAt,
      //   endAt: endAt,
      // }
      // const headers = { shopObjectId: shopObjectId }

      // report({
      //   url: '/receipt/v1.0/productsellbyplureport/' + uid + '/excel',
      //   params: params,
      //   method: 'GET',
      //   headers: headers,
      //   responseType: 'blob', // important
      // })
      //   .then((response) => {
      //     const url = window.URL.createObjectURL(new Blob([response.data]))
      //     const link = document.createElement('a')
      //     link.href = url
      //     link.setAttribute(
      //       'download',
      //       'รายงานสรุปจำนวนการขายสินค้าตามขนาดบรรจุ.xlsx'
      //     ) //or any other extension
      //     document.body.appendChild(link)
      //     link.click()
      //   })
      //   .catch((error) => {
      //     console.log(error)
      //   })
    },
    floatValue(value) {
      return parseFloat(value)
    }
  },
}
</script>
